<script setup>
import { Head, Link } from "@inertiajs/vue3";
import ApplicationLogo from "@/Components/ApplicationLogo.vue";
import { onMounted } from "vue";
import { register } from "swiper/element/bundle";
import CommonLayout from "@/Layouts/CommonLayout.vue";
import SingleFAQ from "@/Components/SingleFAQ.vue";
import TiltCard from "@/Components/TiltCard.vue";

defineProps({
    canLogin: {
        type: Boolean,
    },
    canRegister: {
        type: Boolean,
    },
});

onMounted(() => {
    register();

    // swiper element
    const swiperEl = document.querySelector("swiper-container");

    // swiper parameters
    const swiperParams = {
        injectStyles: [
            ".swiper-free-mode>.swiper-wrapper{transition-timing-function : linear;align-items:center;}",
        ],
        slidesPerView: 3,
        spaceBetween: 40,
        loop: true,
        autoplay: {
            delay: 1,
            disableOnInteraction: false,
        },
        freeMode: {
            momentum: false,
        },
        speed: 10000,

        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
        on: {
            init() {
                // ...
            },
        },
    };

    // now we need to assign all parameters to Swiper element
    Object.assign(swiperEl, swiperParams);

    // and now initialize it
    swiperEl.initialize();
});
</script>

<template>
    <CommonLayout :can-login="canLogin" :can-register="canRegister">
        <Head title="Welcome" />
        <div class="pt-12 pb-24 md:pt-24">
            <div class="container mx-auto">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div class="col">
                        <div class="mt-3">
                            <h2
                                class="uppercase text-white text-4xl leading-14 md:text-5xl md:leading-18 mb-7"
                            >
                                Истински коли, истинско изживяване!
                            </h2>
                            <p
                                class="text-white md:text-lg md:font-semibold mb-14"
                            >
                                Приготви се да се състезаваш в най-истинските
                                симулатори!
                            </p>
                            <div class="gamfi_v2_hero_btns">
                                <Link
                                    :href="route('book')"
                                    class="glow-on-hover text-white font-russo uppercase px-11 py-4 justify-center relative inline-flex overflow-hidden before:absolute before:top-0 before:left-0 before:z-[-1] before:w-[calc(100%+4px)] before:h-[calc(100%+4px)] before:content-[''] before:bg-[length:400%] after:top-0 after:left-0 after:z-[-1] after:absolute after:w-full after:h-full after:content-[''] h-[56px]"
                                    >Резервирай</Link
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <img
                            src="/assets/icons/speedlounge-home-hero.png"
                            class="mx-auto"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="py-12 bg-slate-700/50 backdrop-blur-md">
            <swiper-container init="false" class="container mx-auto">
                <swiper-slide>
                    <a href="https://arenatobacco.com/" target="_blank">
                        <img
                            class="mx-auto"
                            src="/assets/partners/partner-image.png"
                            alt="Arena Tobacco Logo"
                        />
                    </a>
                </swiper-slide>
                <swiper-slide>
                    <a href="https://webtospec.com/" target="_blank">
                        <img
                            class="mx-auto"
                            src="/assets/partners/partner-image2.png"
                            alt="Web to Spec Logo"
                        />
                    </a>
                </swiper-slide>
                <swiper-slide>
                    <a href="https://roadhelpplovdiv.com/" target="_blank">
                        <img
                            class="mx-auto"
                            src="/assets/partners/partner-image3.png"
                            alt="Road Help Plovdiv Logo"
                        />
                    </a>
                </swiper-slide>
                <swiper-slide>
                    <a href="https://arenatobacco.com/" target="_blank">
                        <img
                            class="mx-auto"
                            src="/assets/partners/partner-image.png"
                            alt="Arena Tobacco Logo"
                        />
                    </a>
                </swiper-slide>
                <swiper-slide>
                    <a href="https://webtospec.com/" target="_blank">
                        <img
                            class="mx-auto"
                            src="/assets/partners/partner-image2.png"
                            alt="Web to Spec Logo"
                        />
                    </a>
                </swiper-slide>
                <swiper-slide>
                    <a href="https://roadhelpplovdiv.com/" target="_blank">
                        <img
                            class="mx-auto"
                            src="/assets/partners/partner-image3.png"
                            alt="Road Help Plovdiv Logo"
                        />
                    </a>
                </swiper-slide>
            </swiper-container>
        </div>
        <div class="container mx-auto py-12">
            <div class="grid grid-cols-6 md:grid-cols-12 gap-10">
                <div class="col-span-6 lg:col-span-3 justify-center flex">
                    <a
                        href="https://instagram.com/speedloungebg"
                        target="_blank"
                    >
                        <TiltCard>
                            <v-icon
                                fill="#ffffff"
                                scale="3"
                                name="bi-instagram"
                            />
                            <span class="text-white text-lg font-semibold mt-4">
                                @speedloungebg
                            </span>
                        </TiltCard>
                    </a>
                </div>
                <div class="col-span-6 lg:col-span-3 justify-center flex">
                    <a
                        href="https://www.facebook.com/speedloungebg"
                        target="_blank"
                    >
                        <TiltCard>
                            <v-icon
                                fill="#ffffff"
                                scale="3"
                                name="fa-facebook-f"
                            />
                            <span class="text-white text-lg font-semibold mt-4">
                                @speedloungebg
                            </span>
                        </TiltCard>
                    </a>
                </div>
                <div class="col-span-6 lg:col-span-3 justify-center flex">
                    <a
                        href="https://www.tiktok.com/@speedloungebg"
                        target="_blank"
                    >
                        <TiltCard>
                            <v-icon fill="#ffffff" scale="3" name="fa-tiktok" />
                            <span class="text-white text-lg font-semibold mt-4">
                                @speedloungebg
                            </span>
                        </TiltCard>
                    </a>
                </div>
                <div class="col-span-6 lg:col-span-3 justify-center flex">
                    <a
                        href="https://www.youtube.com/@SpeedLoungeRecords"
                        target="_blank"
                    >
                        <TiltCard>
                            <v-icon
                                fill="#ffffff"
                                scale="3"
                                name="ri-youtube-line"
                            />
                            <span
                                class="text-white text-lg font-semibold mt-4 text-center"
                            >
                                @Speed Lounge Records
                            </span>
                        </TiltCard>
                    </a>
                </div>
            </div>
        </div>
        <div class="container mx-auto py-12">
            <div class="grid grid-cols-12">
                <div class="col-span-12 lg:col-span-8 lg:col-start-3">
                    <SingleFAQ>
                        <template #question>Колко струва?</template>
                        <template #answer
                            >Цената по време на beta testing е 16лв на
                            час.</template
                        >
                    </SingleFAQ>
                    <SingleFAQ>
                        <template #question
                            >Има ли минималната възраст?</template
                        >
                        <template #answer
                            >Симулаторите ни са максимално реалистични, което
                            налага да изискваме минимална възраст на пилотите от
                            16г. За повече подробности прочетете
                            <a
                                target="_blank"
                                :href="route('safety')"
                                class="underline text-gray-400 hover:text-gray-100"
                                >Важна информация за безопасност</a
                            ></template
                        >
                    </SingleFAQ>
                    <SingleFAQ>
                        <template #question>Къде се намира?</template>
                        <template #answer
                            >Локацията ни е
                            <a
                                href="https://maps.app.goo.gl/kyiXpQh25Knca4an7"
                                target="_blank"
                                >Бизнес парк Флавия, бул. Цар Борис ІІІ
                                Обединител №179, гр. Пловдив</a
                            ></template
                        >
                    </SingleFAQ>
                    <SingleFAQ>
                        <template #question>Какво е работното време?</template>
                        <template #answer
                            >Работим от 9:00ч сутринта до 01:00ч вечерта всеки
                            ден.</template
                        >
                    </SingleFAQ>
                    <SingleFAQ>
                        <template #question>Какви игри имате?</template>
                        <template #answer
                            >Разполагаме със следните игри:
                            <ul class="list-disc pl-4">
                                <li>Automobilista 2 (+ full DLC)</li>
                                <li>Assetto Corsa Evo</li>
                                <li>
                                    Assetto Corsa (+ full Content Manager + full
                                    DLC pack)
                                </li>
                                <li>Assetto Corsa Competizione</li>
                                <li>BeamNG.drive</li>
                                <li>Live for Speed (+S3 License)</li>
                                <li>DiRT Rally 2.0</li>
                                <li>Euro Truck Simulator 2</li>
                            </ul>
                        </template>
                    </SingleFAQ>
                    <SingleFAQ>
                        <template #question
                            >Мога ли да внасям питиета в симулаторите?</template
                        >
                        <template #answer
                            >Да, във всеки симулатор има място за напитки. До
                            всяка кола има разположена и количка за напитки и
                            аксесоари.</template
                        >
                    </SingleFAQ>
                    <SingleFAQ>
                        <template #question
                            >Мога ли да пуша наргиле докато карам?</template
                        >
                        <template #answer
                            >Да, предвидили сме безопасно място за наргиле на
                            количка до всеки симулатор.</template
                        >
                    </SingleFAQ>
                    <SingleFAQ>
                        <template #question
                            >Какви са воланите и педалите?</template
                        >
                        <template #answer
                            >Всичко е изработено от нулата от нас. Воланът се
                            управлява от 30Nm трифазен серво мотор. Педалите са
                            оригиналните за колите свързани към наши конструкции
                            от 30мм неръждаема стомана.</template
                        >
                    </SingleFAQ>
                    <SingleFAQ>
                        <template #question
                            >Каква е скоростната кутия?</template
                        >
                        <template #answer
                            >Както всички останали механизми така и скоростната
                            кутия е наша изработка. От конструкцията до
                            създаването на платките и програмирането на
                            микроконтролерите, всичко се прави от нас.
                            Скоростната кутия използва иновативен дизайн,
                            позволяващ както H-pattern/ръчни скорости, така и
                            секвенциален режим на работа, в който се преминава
                            само с натискането на 1 бутон.</template
                        >
                    </SingleFAQ>
                </div>
            </div>
        </div>
    </CommonLayout>
</template>

<script setup>
import { ref } from "vue";

function throttle(func, delay) {
    let lastCall = 0;
    return (...args) => {
        const now = new Date().getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return func(...args);
    };
}

const rotate = ref({ x: 0, y: 0 });

const onMouseMove = throttle((e) => {
    const card = e.currentTarget;
    const box = card.getBoundingClientRect();
    const x = e.clientX - box.left;
    const y = e.clientY - box.top;
    const centerX = box.width / 2;
    const centerY = box.height / 2;
    const rotateX = (y - centerY) / 7;
    const rotateY = (centerX - x) / 7;

    rotate.value = { x: rotateX, y: rotateY };
}, 100);

const onMouseLeave = () => {
    rotate.value = { x: 0, y: 0 };
};
</script>

<style scoped>
.card {
    perspective: 1000px;
}
</style>

<template>
    <div
        class="card relative h-52 w-52 rounded-xl bg-white transition-[all_400ms_cubic-bezier(0.03,0.98,0.52,0.99)_0s] will-change-transform"
        @mousemove="onMouseMove"
        @mouseleave="onMouseLeave"
        :style="{
            transform: `perspective(1000px) rotateX(${rotate.x}deg) rotateY(${rotate.y}deg) scale3d(1, 1, 1)`,
            transition: 'all 400ms cubic-bezier(0.03, 0.98, 0.52, 0.99) 0s',
        }"
    >
        <div
            class="pulse absolute -inset-2 rounded-lg bg-gradient-to-r from-blue-600 via-purple-600 to-indigo-600 opacity-75 blur-xl"
        ></div>
        <div
            class="relative flex flex-col h-full w-full select-none items-center justify-center rounded-lg bg-slate-900 text-sm font-light text-slate-300"
        >
            <slot />
        </div>
    </div>
</template>
